<template>
  <section class="w-full">
    <div
      class="w-full card-body flex flex-col flex-between min-h-[200px] overflow-hidden border min-w-full sm:min-w-[382px]"
      :style="`background-image: linear-gradient(to right, ${bgColor}, ${
        bgColor + '90'
      } )`"
    >
      <div class="flex-1 flex items-start">
        <div class="content-section w-full">
          <div class="flex flex-1 items-center gap-5">
            <!-- <img :src="icon" alt="" class="icon w-24 h-24" v-if="icon" /> -->
            <div class="w-full flex gap-2 items-center justify-between">
              <div>
                <app-i18n
                  :code="label"
                  class="text-xl font-semibold text-white"
                ></app-i18n>
                <!-- <span class="material-icons float-right cursor-pointer" style="color: #c0bcbc; font-size: 1.5rem;">info</span> -->
                <!-- <info-icon
                  size="1.5x"
                  class="custom-class cursor-pointer"
                  style="color: #c0bcbc"
                ></info-icon> -->
              </div>
              <div class="amount whitespace-wrap text-white flex gap-1">
                <span>
                  {{ currentAmount }}
                </span>
                <sup class="text-sm">{{ currentCurrency }}</sup>
              </div>
            </div>
          </div>
        </div>

        <div class="available-balance flex justify-end" v-if="availableBalance">
          <div>
            <app-i18n code="Available"></app-i18n> :
            <span class="balance"> {{ availableBalanceValue }}</span>
          </div>
        </div>
      </div>

      <div class="card-actions">
        <!-- <div flat no-caps dense class="no-hover">
          <app-i18n code="common.viewBreakdown"></app-i18n>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  data: () => {
    return {
      bg: '#3a9c97'
    }
  },
  name: 'app-wallet-card',
  props: {
    bgColor: {
      type: String,
      required: false,
      default: '#3a9c97'
    },
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    currency: {
      type: String
    },
    amount: {
      type: Number,
      required: true
    },
    availableBalance: {
      type: Number,
      required: false
    },
    'amount-style': {
      type: String,
      default: 'color: #000000 !important;'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    currentAmount() {
      const price = this.roundNumber(this.amount)
      return this.numberWithCommas(price)
    },
    currentCurrency() {
      return this.currency === 'USD'
        ? this.language == 'ar'
          ? 'دولار'
          : 'USD'
        : this.language == 'ar'
        ? 'جنيه'
        : 'EGP'
    },
    availableBalanceValue() {
      const PRICE = this.roundNumber(this.availableBalance)
      const amount = this.numberWithCommas(PRICE)
      return this.language == 'ar'
        ? `${amount || 0} دولار`
        : `${amount || 0} USD`
    }
    // amountColor() {
    //   return this.value.type == 'credit'
    //     ? { color: '#23C045' }
    //     : { color: '#F14336' }
    // },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} دولار` : `${PRICE} USD`
    },
    roundNumber(number) {
      return (Math.round(number * 100) / 100).toFixed(2)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    numberWithCommas(x) {
      // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: 0 !important;
  border-radius: 15px !important;
  margin-bottom: 1rem !important;
  // min-height: 77px !important;
  // min-height: 9.375rem !important;
}

.content-section {
  padding: 20px !important;
}

.card-actions {
  background: #f8f8f860 !important;
  padding: 1.5rem !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  button {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    text-decoration: underline !important;
    color: #515151 !important;
  }
}

.available-balance {
  padding: 0.25rem !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;

  .balance {
    color: #13b177 !important;
    font-weight: 600 !important;
  }
}

// .icon {
//   margin-right: 1.5rem !important;
// }

.amount {
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2rem;
  margin-top: 0.5rem;
}

// .avatar {
//   box-shadow: 0px 2.4px 6px rgba(0, 0, 0, 0.1);
//   margin-right: 12px;
// }

// .details-label {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
//   display: flex;
//   align-items: center;
//   color: #000000;
// }
// .details-value {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 18px;
//   color: #6C6C6C;
// }
</style>

<style lang="scss" scoped>
@media (max-width: 1439px) {
  .q-card {
    // min-height: calc(80px + (77 - 80) * ((100vw - 320px) / (1440 - 320))) !important;
    border-radius: calc(
      6px + (15 - 6) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .content-section {
    padding: calc(
      12px + (20 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }
  .card-actions {
    padding: calc(
      12px + (24 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    // border-bottom-left-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1440 - 320))) !important;
    // border-bottom-right-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .avatar {
    margin-right: calc(
      8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .amount {
    font-size: calc(
      16px + (28 - 16) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .details-label {
    font-size: calc(
      12px + (16 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    line-height: calc(
      18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .details-value {
    font-size: calc(
      10px + (12 - 10) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    line-height: 18px;
  }
}
</style>
