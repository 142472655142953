/* eslint-disable */

import { i18n } from '@/locales/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
// import JsonField from '@/shared/fields/json-field';
import DateTimeField from '@/shared/fields/date-time-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import IntegerField from '@/shared/fields/integer-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';

function label(name) {
  return i18n(`wallet.fields.${name}`);
}

const fields = {
  id: new IdField('userId', label('id'), { required: true }),
  userId: new StringField('userId', label('userId'), { required: true }),
  balance: new DecimalField('balance', label('balance'), { required: true }),
  actualBalance: new DecimalField('actualBalance', label('actualBalance'), { required: true }),
  userName: new StringField('userName', label('userName'), { required: false }),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), { required: true }),
  email: new StringField('email', label('email'), { required: false }),
  
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class WalletModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
