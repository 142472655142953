<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('wallet.view.title') }}
      </h2>
    </div>
    <div class="intro-y box rounded-xl p-3.5 md:p-5 mt-5">
      <!-- <wallet-view-user-profile :userId="id"></wallet-view-user-profile> -->
      <section>
        <div class="flex justify-between">
          <h6 class="caption">
            <app-i18n code="wallet.caption"></app-i18n>
          </h6>
          <button
            id="refreshBtn"
            type="button"
            @click="doRefresh"
            class="btn bg-theme-31 text-white mb-2 flex items-center gap-2 transition duration-700 ease-in-out"
            :disabled="loading"
          >
            <RefreshCcwIcon
              class="h-5 w-5 refreshIcon transition duration-500"
            />
            <app-i18n code="common.refresh"></app-i18n>
          </button>
        </div>
        <div class="flex flex-wrap justify-center gap-5 mt-2 py-3 md:px-12">
          <div
            class="intro-y m-auto"
            style="width: 24rem"
            v-if="loading && !record"
          >
            <loading-spinner type="controller" :loading="loading" />
          </div>
          <div
            class="intro-y"
            style="width: 24rem"
            v-for="(wallet, i) in record"
            :key="wallet.id"
          >
            <app-wallet-card
              v-if="wallet && !loading"
              :amount="wallet.amount"
              :label="i18n(`wallet.fields.${wallet.id}`)"
              :currency="wallet.currency"
              :bgColor="['#3a9c97', '#800b8c'][i]"
              icon="/images/icons/ic-wallet-1.png"
            ></app-wallet-card>
            <app-wallet-card-loading v-else />
          </div>
        </div>
      </section>
    </div>

    <!-- <app-wallet-transaction-table :walletId="id"></app-wallet-transaction-table> -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import WalletCard from '@/views/wallet/wallet-card.vue'
import WalletCardLoading from '@/views/wallet/wallet-card-loading.vue'
// import walletTransactionTable from '@/views/wallet/wallet-transaction-table.vue'
// import WalletViewProfile from '@/views/wallet/wallet-view-profile.vue'
import { WalletModel } from '@/store/wallet/wallet-model'

const { fields } = WalletModel
export default defineComponent({
  components: {
    [WalletCard.name]: WalletCard,
    [WalletCardLoading.name]: WalletCardLoading
    // [WalletViewProfile.name]: WalletViewProfile
    // [walletTransactionTable.name]: walletTransactionTable
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.wallet')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.transactions')
    }
  },
  async beforeMount() {
    await this.doFind()
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'wallet/view/record',
      loading: 'wallet/view/loading'
    }),
    fields() {
      return fields
    }
  },
  methods: {
    ...mapActions({
      doFind: 'wallet/view/doFind',
      doRefresh: 'wallet/view/doRefresh'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} دولار` : `${PRICE} USD`
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
.caption {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  margin: 0.5rem 0 0 !important;
}
</style>
