<template>
  <!--  border border-blue-300 rounded-md p-4 max-w-sm mx-auto -->
  <div class="card-body w-full">
    <div class="flex space-x-4 content-section">
      <div class="animate-skeleton rounded-full bg-slate-200 h-16 w-16" style="background: #f8f8f8;"></div>
      <div class="flex-1 space-y-6 py-1">
        <div class="flex items-center gap-2">
          <div class="animate-skeleton h-3 w-1/2 bg-slate-200 rounded" style="background: #f8f8f8;"></div>
          <div class="animate-skeleton rounded-full bg-slate-200 h-5 w-5" style="background: #f8f8f8;"></div>
        </div>
        <div class="grid grid-cols-6 gap-2">
          <div class="animate-skeleton h-5 bg-slate-200 rounded col-span-4" style="background: #f8f8f8;"></div>
          <div class="animate-skeleton h-5 bg-slate-200 rounded col-span-2" style="background: #f8f8f8;"></div>
        </div>
        <!-- <div class="space-y-3">
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-slate-200 rounded col-span-2"></div>
            <div class="h-2 bg-slate-200 rounded col-span-1"></div>
          </div>
          <div class="h-2 bg-slate-200 rounded"></div>
        </div> -->
      </div>
    </div>
    <div class="card-actions"></div>
  </div>
</template>

<script>
export default {
  name: 'app-wallet-card-loading'
}
</script>

<style lang="scss" scoped>
.card-body {
  background: #FFFFFF !important;
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
  padding: 0 !important;
  border-radius: 15px !important;
  margin-bottom: 1rem !important;
  // min-height: 77px !important;
  // min-height: 9.375rem !important;
}

.content-section {
  padding: 20px !important;
}

.card-actions {
  background: #f8f8f8 !important;
  padding: 1.5rem !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  .q-btn {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    text-decoration: underline !important;
    color: #515151 !important;
  }
}

.available-balance {
  padding: 0.25rem !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;

  .balance {
    color: #13B177 !important;
    font-weight: 600 !important;
  }
}

.icon {
  margin-right: 1.5rem !important;
}

.title {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.9375rem !important;
  line-height: 1.5rem !important;
  color: #000000 !important;
  margin-right: 0.5rem !important;
}

.amount {
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2rem;
  margin-top: 0.5rem;
}

// .avatar {
//   box-shadow: 0px 2.4px 6px rgba(0, 0, 0, 0.1);
//   margin-right: 12px;
// }

// .details-label {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
//   display: flex;
//   align-items: center;
//   color: #000000;
// }
// .details-value {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 18px;
//   color: #6C6C6C;
// }
</style>

<style lang="scss" scoped>
@media (max-width: 1439px) {
  .q-card {
    // min-height: calc(80px + (77 - 80) * ((100vw - 320px) / (1440 - 320))) !important;
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .content-section {
    padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1440 - 320))) !important;
  }
  .card-actions {
    padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1440 - 320))) !important;
    // border-bottom-left-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1440 - 320))) !important;
    // border-bottom-right-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1440 - 320))) !important;
  }
  .avatar {
    margin-right: calc(8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .amount {
    font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .details-label {
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1440 - 320))) !important;
    line-height: calc(18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .details-value {
    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1440 - 320))) !important;
    line-height: 18px;
  }
}
</style>
